body {
  margin: 0px;
  overflow: hidden;
}

a:link, a:visited {
  color: white;
  font-variant: small-caps;
}

.App {
  text-align: center;
}

#earth {
  position: absolute;
  left: 0;
  top: 0;
}

#intro-screen {
  cursor: default;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgba(41, 41, 41, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 5;
}

#continue-button {
  background-color: rgba(41, 41, 41, 0.75);
  border-radius: 0.5rem;
  box-shadow: none;
  border: none;
  cursor: pointer;
  padding: .5rem 1rem;
  margin: 1rem 0 0 0;
}

#controls-text {
  margin: 0 0 1rem 0;
}

.underlined {
  text-decoration: underline 0.05rem;
  text-underline-offset: 0.2rem;
  margin: 0 0 0.3rem 0;
}

#container {
  cursor: default;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 2;
}

#Article-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 50vw;
  min-height: 100vh;
  background-image: url("night-sky.png");
  max-height: 100vh;
  scroll-behavior: smooth;
  overflow-y:auto;
  overflow-x: hidden;
}

#Globe {
  min-width: 50vw;
  min-height: 100vh;
}

#footer {
  cursor: default;
  position: absolute;
  bottom: 0;
  min-height: 2.5rem;
  padding: 0.5rem 0.5rem;
  background-color: rgba(60, 60, 60, 0.9);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  z-index: 4;
}

.footer-text {
  color: white;
  font-size: 0.75rem;
  margin: 0;
}

#navigator {
  cursor: default;
  text-align: left;
  position: absolute;
  top: 0;
  min-height: 2.5rem;
  padding: 0.5rem .5rem;
  background-color: rgba(60, 60, 60, 0.9);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  z-index: 4;
}

.nav-title {
  color: white;
  font-size: 0.75rem;
  margin: 0;
}

.nav-text {
  color: white;
  font-size: 0.75rem;
  margin: 0;
}

.nav-subtitle {
  color: white;
  font-size: 0.75rem;
  margin: 0 0 0.25rem 0;
}

#popup-screen-container {
  cursor: default;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

#popup-screen {
  background-color: rgba(60, 60, 60, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 5;
  min-height: 10vh;
  min-width: 50vw;
  padding: 5vh 2vw; 
}

#popup-text{
  padding: 0 5%;
}

#close-button {
  background-color: rgba(150, 150, 150, 0.5);
  border-radius: 0.5rem;
  box-shadow: none;
  border: none;
  cursor: pointer;
  padding: .5rem 1rem;
  margin: 1rem 0 0 0;
  justify-self: flex-end;
}

#close-button:hover {
  background-color: rgba(150, 150, 150, 0.9);
} 

.popup-title {
  color: white;
  font-size: 3rem;
  margin: 0 0 1rem 0;
}

.popup-text {
  color: white;
  font-size: 1.5rem;
  margin: 0;
}

.popup-subtitle {
  color: rgb(220, 220, 220);
  font-size: 1.25rem;
  margin: 0 0 1rem 0;
}

.popup-button {
  color: white;
  font-size: 1.5rem;
  margin: 0;
}

.title-button {
  background-color: rgba(150, 150, 150, 0.5);
  border-radius: 0.1rem;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

.title-button:hover {
  background-color: rgba(150, 150, 150, 0.9);
}

.button {
  background-color: rgba(150, 150, 150, 0.5);
  border-radius: 0.1rem;
  border: none;
  cursor: pointer;
  padding: .3rem .3rem;
  margin: .25rem 0.05rem 0 0.05rem;
  color: white;
}

.button:hover {
  background-color: rgba(150, 150, 150, 0.9);
}

#Article {
  margin: 2rem 0 2rem 0;
}

.article-paragraph {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
}

.article-header {
  color: white;
  font-size: 2rem;
  margin: 0 0 1rem 0;
  text-align: center;
  align-self: center;
}

.article-text {
  text-align: left;
  color: white;
  font-size: 1rem;
  margin: 0;
  padding: .5rem 0;
}

.vega-embed {
  float: right;
  margin: 1rem;
}

.intext-graph {
  width: 45%;
}

.icon-link {
  font-size: 2rem;
  color: white;
  align-self: center;
  margin-top: 1rem;
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
	40% {
    transform: translateY(-10px);
  }
	60% {
    transform: translateY(-5px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}